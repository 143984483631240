<template>
  <div>
    <!-- Folder Container -->
    <v-row class="transparent mb-4">
      <div v-if="trashesFolders && trashesFolders.length === 0" class="pl-3">
        No trashes folder found!
      </div>
      <v-col
        v-for="folder in trashesFolders"
        :key="folder.id"
        cols="4"
        lg="3"
        md="4"
        sm="6"
        class="mt-2 text--black"
      >
        <v-card class="mx-auto rounded-xl k-folder-box" :elevation="0">
          <v-row>
            <v-col cols="1">
              <v-icon color="primary--text" class="black--text lighten-3">
                mdi-delete-circle
              </v-icon>
            </v-col>
            <v-col cols="9" class="text-sm-left">
              <v-card-text
                class="text-13 capitalize black--text k-folder-content"
              >
                <div class="folder-text-name" :title="folder.name">
                  {{ folder.name }}
                </div>
                <small
                  >{{ folder.trashed_recordings_count }} recording{{
                    folder.trashed_recordings_count == 1 ? "" : "s"
                  }}
                </small>
              </v-card-text>
            </v-col>
            <v-col cols="1" class="k-folder-menu">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="primary--text"
                    class="black--text lighten-3 k-folder-menu-icon"
                  >
                    mdi-dots-horizontal
                  </v-icon>
                </template>
                <v-list class="k-folder-dropdown">
                  <div
                    @click="handleRestoreFolder(folder)"
                    class="k-folder-dropdown-item"
                  >
                    Restore
                  </div>
                  <div
                    @click="openForcedDeleteDialog(folder)"
                    class="k-folder-dropdown-item"
                  >
                    Permanent Delete
                  </div>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- Edit Folder -->
      <forced-delete-folder-dialog
        ref="forcedDeleteFolderDialog"
        @load-folders="myFolders"
      ></forced-delete-folder-dialog>
      <!-- Unarchive folder -->
      <restore-folder-dialog
        ref="restoreFolderDialog"
        @load-folders="myFolders"
      ></restore-folder-dialog>
    </v-row>
  </div>
</template>

<script>
// import { mapActions } from "vuex";
import ResponseHelper from "../../utils/ResponseHelper";
import ForcedDeleteFolderDialog from "../Folders/ForcedDeleteFolderDialog.vue";
import RestoreFolderDialog from "../Folders/RestoreFolderDialog.vue";
export default {
  name: "Trashes",
  props: {
    trashesFolders: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ForcedDeleteFolderDialog,
    RestoreFolderDialog,
  },
  data() {
    return {
      toast: new ResponseHelper(),
      tab: null,
      loading: false,
      timerId: null,
      timer: 0,
    };
  },
  created() {},
  watch: {},
  computed: {},
  methods: {
    // ...mapActions("folders", [""]),

    async myFolders(Id) {
      this.$emit("load-folders", Id);
    },
    handleRestoreFolder(folder) {
      this.$refs.restoreFolderDialog.openDialog(folder);
    },
    openForcedDeleteDialog(folder) {
      this.$refs.forcedDeleteFolderDialog.openDialog(folder);
    },
  },
  beforeDestroy() {
    clearTimeout(this.timerId);
  },
};
</script>

<style scoped>
.k-folder-box {
  border: 2px solid #ddd;
  padding: 15px 16px;
  max-height: 90px;
  border-radius: 15px !important;
}
.k-folder-box:hover {
  cursor: pointer;
  border: 2px solid blue;
}
.k-folder-content {
  height: 40px;
  padding: 0px !important;
  padding-left: 6px !important;
  margin: 0px !important;
  font-weight: 900 !important;
}
.k-folder-content > small {
  font-weight: 200 !important;
}
.folder-text-name {
  color: #000 !important;
  font-weight: 600 !important;
  padding: 0px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* Folder dropdown styles */
.k-folder-menu {
  padding: 0px;
  padding-top: 10px;
}
.k-folder-menu > .k-folder-menu-icon {
  border-radius: 50%;
  padding: 2px;
}
.k-folder-menu-icon:hover {
  border-radius: 50%;
  padding: 2px;
  background-color: #ddd;
  color: #000 !important;
}
.k-folder-dropdown {
  width: 180px !important;
  text-align: left !important;
  padding: 10px 10px;
}
.k-folder-dropdown-item {
  padding: 0px 13px;
  margin-bottom: 2px;
  font-size: 13px;
  font-weight: 600;
}
.k-folder-dropdown > .k-folder-dropdown-item {
  cursor: pointer;
  border-radius: 50px;
  height: 35px !important;
  line-height: 32px;
  border: 1px solid #fff !important;
}
.k-folder-dropdown > .k-folder-dropdown-item:hover {
  background-color: #dbdbdb;
  border: 1px solid #ddd !important;
}
/* ./ Dropdown menu */
.renaming-field {
  max-height: 21px !important;
  max-width: 100%;
}
.renaming-field:focus {
  outline: none;
}
.renaming-field::placeholder {
  font-size: 12px;
  font-weight: 400;
}
</style>

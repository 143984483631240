<template>
  <div>
    <div>
      <v-toolbar flat class="transparent font-weight-bold ml-n4 mb-4">
        My Folders
        <v-btn
          @click="createNewFolder"
          class="capitalize ml-6"
          color="primary"
          :loading="loading"
          rounded
          small
        >
          <v-icon>mdi-plus</v-icon>
          New Folder
        </v-btn>
      </v-toolbar>
    </div>
    <!-- folder container -->
    <div>
      <!-- Recursive Folder Container -->
      <folders
        @load-folders="myFolders"
        :folders-details="displayedFolders"
      ></folders>
      <v-btn
        v-if="remainingFolders.length > 0"
        @click="showMore"
        class="capitalize show-more-btn"
        :elevation="0"
        plain
        rounded
        dense
      >
        Show More ({{ remainingFolders.length }})
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ResponseHelper from "../utils/ResponseHelper";
import Folders from "./MyFolders/Folders.vue";
export default {
  name: "MyFolders",
  components: {
    Folders,
  },
  data() {
    return {
      folders: [],
      toast: new ResponseHelper(),
      default_folder_name: {
        name: "Untitled Folder",
      },
      loading: false,
      timerId: null,
      timer: 0,
      displayLimit: this.setDefaultListShow(),
    };
  },
  created() {
    this.myFolders();
  },
  beforeUpdate() {
    this.folders = this.folderDetails;
  },
  watch: {
    folderDetails: {
      handler(newFolder) {
        this.folders = newFolder;
      },
    },
  },
  computed: {
    ...mapGetters("folders", ["folderDetails"]),

    displayedFolders() {
      return this.folders.slice(0, this.displayLimit);
    },
    remainingFolders() {
      return this.folders.slice(this.displayLimit);
    },
  },
  methods: {
    ...mapActions("folders", ["my_folders", "create_new_folder"]),

    showMore() {
      if (this.$route.name !== "Dashboard") {
        this.displayLimit += 8;
      } else {
        this.$router.push({ name: "MyFolders" });
      }
    },
    setDefaultListShow() {
      if (this.$route.name !== "Dashboard") {
        return 16;
      } else {
        return 8;
      }
    },
    async myFolders() {
      try {
        const response = await this.my_folders();
        this.folders = response.data;
      } catch (e) {
        this.toast.sendError(e);
      }
    },
    async createNewFolder() {
      this.loading = true;
      try {
        const folder_name = this.default_folder_name;
        const response = await this.create_new_folder(folder_name);
        this.loading = false;
        this.myFolders();
        this.toast.sendSuccess(response);
      } catch (e) {
        this.loading = false;
        this.toast.sendError(e);
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timerId);
  },
};
</script>

<style scoped>
.show-more-btn {
  border: 1px solid #ddd !important;
  background-color: #fff !important;
  color: #000 !important;
}
.show-more-btn:hover {
  border: 1px solid blue !important;
  background-color: #fff !important;
  font-weight: 400;
}
</style>

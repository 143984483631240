<template>
  <div>
    <v-overlay v-model="loading">
      <v-progress-circular
        indeterminate
        size="40"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col cols="12" md="8">
        <small class="grey--text text-left d-block"></small>
      </v-col>
      <v-col cols="12 text-left text-md-right" md="4">
        <small @click="goBack" class="pointer mr-4">
          <v-icon>mdi-keyboard-backspace</v-icon>
          Go back
        </small>
      </v-col>
    </v-row>
    <v-container class="tab-container mt-4">
      <v-tabs v-model="tab" fixed>
        <v-tab class="custom-tab" @click="reloadComponent(1)">Recordings</v-tab>
        <v-tab class="custom-tab" @click="reloadComponent(2)">Archive</v-tab>
        <v-tab class="custom-tab" @click="reloadComponent(3)"
          >Recycle Bin</v-tab
        >
      </v-tabs>

      <v-tabs-items v-model="tab" class="tabs-content">
        <v-tab-item :key="reloadKey + '1'" class="tab-item">
          <v-card :elevation="0" class="tabs-item-card">
            <!-- folder toolbar -->
            <MyFolders :key="reloadKey" />
          </v-card>
        </v-tab-item>

        <v-tab-item :key="reloadKey + '2'">
          <v-card :elevation="0" class="tabs-item-card">
            <!-- archives folders -->
            <MyArchives :key="reloadKey" />
          </v-card>
        </v-tab-item>

        <v-tab-item :key="reloadKey + '3'">
          <v-card :elevation="0" class="tabs-item-card">
            <MyTrashes :key="reloadKey" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
// import { mapActions } from "vuex";
import ResponseHelper from "../../utils/ResponseHelper";
import MyFolders from "../../components/MyFolders.vue";
import MyArchives from "../../components/MyArchives.vue";
import MyTrashes from "../../components/MyTrashes.vue";
export default {
  name: "MyFoldersView",
  components: {
    MyFolders,
    MyArchives,
    MyTrashes,
  },
  data() {
    return {
      toast: new ResponseHelper(),
      favicon_url: "",
      fetch_my_folders: [],
      reloadKey: 0,
      tab: null,
      loading: false,
      timerId: null,
      timer: 0,
    };
  },
  mounted() {},
  watch: {},
  created() {},
  computed: {},
  methods: {
    // ...mapActions("folders", ["my_folders"]),

    reloadComponent(tabIndex) {
      this.reloadKey += 1; // Increment the reload key
      this.tab = tabIndex; // Change the active tab to trigger a re-render
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
/* Tab styles */
.tab-container {
  padding: 0px;
}
.tabs-content {
  margin-top: 10px;
  /* padding: 20px; */
  background-color: transparent !important;
  border-bottom: none !important;
}
.tabs-item-card {
  border: none;
  background-color: transparent !important;
}
.custom-tab {
  font-size: 14px !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
}
</style>

<template>
  <div>
    <div>
      <v-toolbar flat class="transparent font-weight-bold ml-n4 mb-4">
        My Trashes
      </v-toolbar>
    </div>
    <!-- folder container -->
    <div>
      <!-- Folder Container -->
      <trashes
        @load-folders="myTrashes"
        :trashes-folders="displayedFolders"
      ></trashes>
      <v-btn
        v-if="remainingFolders.length > 0"
        @click="showMore"
        class="capitalize show-more-btn"
        :elevation="0"
        rounded
        dense
      >
        Show More ({{ remainingFolders.length }})
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ResponseHelper from "../utils/ResponseHelper";
import Trashes from "./MyFolders/Trashes.vue";
export default {
  name: "MyTrashes",
  components: {
    Trashes,
  },
  data() {
    return {
      folders: [],
      toast: new ResponseHelper(),
      loading: false,
      timerId: null,
      timer: 0,
      displayLimit: this.setDefaultListShow(),
    };
  },
  created() {
    this.myTrashes();
  },
  updated() {
    this.folders = this.trashes_folders;
  },
  watch: {
    trashes_folders: {
      handler(newFolder) {
        this.folders = newFolder;
      },
    },
  },
  computed: {
    ...mapGetters("folders", ["trashes_folders"]),

    displayedFolders() {
      return this.folders.slice(0, this.displayLimit);
    },
    remainingFolders() {
      return this.folders.slice(this.displayLimit);
    },
  },
  methods: {
    ...mapActions("folders", ["my_trashes"]),

    showMore() {
      if (this.$route.name !== "Dashboard") {
        this.displayLimit += 8;
      } else {
        this.$router.push({ name: "MyFolders" });
      }
    },
    setDefaultListShow() {
      if (this.$route.name !== "Dashboard") {
        return 16;
      } else {
        return 8;
      }
    },
    async myTrashes() {
      try {
        const response = await this.my_trashes();
        this.folders = response.data;
      } catch (e) {
        this.toast.sendError(e);
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timerId);
  },
};
</script>

<style scoped>
.show-more-btn {
  border: 1px solid #ddd !important;
  background-color: #fff !important;
}
.show-more-btn:hover {
  border: 1px solid blue !important;
  background-color: #fff !important;
  font-weight: 400;
}
</style>

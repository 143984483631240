<template>
  <div>
    <v-dialog max-width="550" v-model="dialog" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 lighten-2">
          Move within My Recordings
          <v-spacer></v-spacer>
          <v-icon @click="closeDialog" size="18" class="white--black">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-left black--text" style="height: 350px">
          <div class="k-base-folder">
            Currently in:
            <p class="text-left k-base-folder-title">
              <v-icon size="18" class="grey--text">mdi-note-multiple</v-icon>
              {{ folder_item.name }}
            </p>
          </div>
          Move to:
          <div class="text-left">
            <div class="k-create-folder-1" @click="createOnModalFolder">
              <v-icon size="20" class="black--text">mdi-plus</v-icon>
              New Folder
            </div>
            <label v-if="isCreatingFolder">
              <div class="folder-selector-box">
                <v-icon size="20" class="black--text folder-icon">
                  mdi-folder-outline
                </v-icon>
                <input
                  v-model="new_folder_name"
                  placeholder="Enter a folder name"
                  class="renaming-field"
                  ref="renaming_new_folder_ref"
                  @click.stop
                  @keydown.enter="handleFolderCreation()"
                />
                <!-- <div>
                </div> -->
              </div>
            </label>
            <label
              class="k-folder-label-box"
              v-if="folder_item.parent_id !== null"
            >
              <input
                type="checkbox"
                :value="root_folder_selected"
                :checked="isRoot"
                @change="selectRootFolder"
                class="folder-selector"
              />
              <div class="folder-selector-box">
                <v-icon size="20" class="black--text folder-icon">
                  mdi-folder-move
                </v-icon>
                ROOT
              </div>
            </label>
            <!-- FolderItem Component -->
            <div v-if="!isRoot">
              <folder-item
                v-for="folder in list_of_folders"
                :key="folder.id"
                :folder="folder"
                :selected_folder_id="selected_folder_id"
                :level="0"
                @folder-selected="updateSelectedFolder"
              ></folder-item>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pb-10 pt-5">
          <v-row>
            <v-col cols="5" sm="5" class="text-left">
              <v-btn
                @click="createOnModalFolder"
                class="black--text capitalize px-4"
                rounded
                outlined
                text
              >
                <v-icon size="18" class="white--black">mdi-plus</v-icon>
                New Folder
              </v-btn>
            </v-col>
            <v-col cols="7" sm="7" class="text-right">
              <v-btn
                @click="closeDialog"
                class="black--text capitalize px-4 mr-3"
                rounded
                outlined
                text
              >
                Cancel
              </v-btn>
              <v-btn
                v-if="!isRoot"
                @click="handleMoveFolder(folder_item)"
                class="capitalize px-4"
                color="primary"
                rounded
                :disabled="isMoveButtonDisabled"
                :loading="loading"
              >
                Move
              </v-btn>
              <v-btn
                v-if="isRoot"
                @click="handleRootFolderMove(folder_item)"
                class="capitalize px-4"
                color="primary"
                rounded
                :loading="loading"
              >
                Move to Root
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FolderItem from "./FolderItem.vue";
import ResponseHelper from "../../utils/ResponseHelper";
export default {
  name: "MoveFolderDialog",
  components: {
    FolderItem,
  },
  props: {
    type: String,
    default: null,
  },
  data() {
    return {
      dialog: false,
      isRoot: false,
      root_folder_selected: null,
      toast: new ResponseHelper(),
      loading: false,
      folder_item: {},
      list_of_folders: [],
      selected_folder_id: null,
      expanded_folders: [],
      new_folder_name: "Untitled folder",
      isCreatingFolder: false,
      timerId: null,
      timer: 0,
    };
  },
  computed: {
    isMoveButtonDisabled() {
      return !this.selected_folder_id;
    },
  },
  methods: {
    ...mapActions("folders", [
      "move_folders_list",
      "move_folder_to_folder",
      "move_folder_to_root",
      "create_new_folder",
    ]),

    async moveFoldersList(excludedId) {
      try {
        const response = await this.move_folders_list(excludedId);
        this.list_of_folders = response.data;
      } catch (e) {
        this.$parent.toast.sendError(e);
      }
    },
    async handleMoveFolder(folder) {
      this.loading = true;
      const form = {
        id: folder.id,
        parent_folder_id: this.selected_folder_id,
      };

      try {
        const response = await this.move_folder_to_folder(form);
        this.$emit("load-folders", folder.parent_id);
        this.loading = false;
        this.closeDialog();
        this.toast.sendSuccess(response);
      } catch (e) {
        this.loading = false;
        this.toast.sendError(e);
      }
    },
    async handleRootFolderMove(folder) {
      this.loading = true;
      try {
        const response = await this.move_folder_to_root(folder.id);
        this.$emit("load-folders", folder.parent_id);
        this.loading = false;
        this.closeDialog();
        this.toast.sendSuccess(response);
      } catch (e) {
        this.loading = false;
        this.toast.sendError(e);
      }
    },
    updateSelectedFolder(folderId) {
      this.selected_folder_id = folderId;
    },
    selectRootFolder() {
      this.isRoot = !this.isRoot;
    },
    createOnModalFolder() {
      this.isCreatingFolder = true;
      this.$nextTick(() => {
        const renameField = this.$refs.renaming_new_folder_ref;
        renameField.focus();
        renameField.select();
      });
    },
    async handleFolderCreation() {
      this.loading = true;
      const form = {
        name: this.new_folder_name,
        parent_id: this.selected_folder_id,
      };
      clearTimeout(this.timerId);
      this.timerId = setTimeout(async () => {
        try {
          const excludeFolderID = this.folder_item.id;
          const response = await this.create_new_folder(form);
          this.moveFoldersList(excludeFolderID);
          this.loading = false;
          this.isCreatingFolder = false;
          this.new_folder_name = "Untitled folder";
          this.toast.sendSuccess(response);
        } catch (e) {
          this.loading = false;
          this.toast.sendError(e);
        }
      }, 200);
    },
    openDialog(folder) {
      this.folder_item = Object.assign({}, folder);
      const Id = folder.id;
      this.moveFoldersList(Id);
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.list_of_folders = [];
      this.selected_folder_id = null;
    },
  },
  beforeDestroy() {
    clearTimeout(this.timerId);
  },
};
</script>

<style scoped>
.k-base-folder {
  padding: 15px 0px;
}
.k-base-folder > .k-base-folder-title {
  margin-left: 30px;
  margin-top: 8px;
  color: grey;
}
.k-create-folder-1 {
  padding: 4px 35px;
  margin-bottom: 4px;
  height: 40px;
  line-height: 30px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #eee !important;
}
.k-create-folder-1:hover {
  background-color: #eee;
}
/** Folder Movement Styles */
.folder-container {
  margin-bottom: 4px;
}
.folder-selector {
  display: none;
}
.folder-selector-box {
  width: 100%;
  margin: 0px;
  padding: 10px 35px;
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid #fff !important;
}
.folder-selector-box:hover {
  background-color: #eee;
}
.child-folder-selector-box {
  width: 100%;
  margin: 0px;
  padding: 10px 60px;
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid #fff !important;
}
.child-folder-selector-box:hover {
  background-color: #eee;
}
.folder-icon {
  color: #000;
}
/* Parent Folder */
.folder-selector:checked + .folder-selector-box {
  border: 2px solid blue !important;
  background-color: blue !important;
  color: #fff !important;
}
.folder-selector:checked + .folder-selector-box .folder-icon {
  color: #fff !important;
  transition: 0s;
}
/* Child Folder */
.folder-selector:checked + .child-folder-selector-box {
  border: 2px solid blue !important;
  background-color: blue !important;
  color: #fff !important;
}
.folder-selector:checked + .child-folder-selector-box .folder-icon {
  color: #fff !important;
  transition: 0s;
}
/** Renaming on-modal new folder */
.renaming-field {
  max-height: 21px !important;
  width: 90% !important;
  margin-left: 4px;
}
.renaming-field:focus {
  outline: none;
}
.renaming-field::placeholder {
  font-size: 12px;
  font-weight: 400;
}
</style>

<template>
  <div>
    <div>
      <v-toolbar flat class="transparent font-weight-bold ml-n4 mb-4">
        My Archives
      </v-toolbar>
    </div>
    <!-- folder container -->
    <div>
      <!-- Folder Container -->
      <archives
        @load-folders="myArchives"
        :archives-folders="displayedFolders"
      ></archives>
      <v-btn
        v-if="remainingFolders.length > 0"
        @click="showMore"
        class="capitalize show-more-btn"
        :elevation="0"
        rounded
        dense
      >
        Show More ({{ remainingFolders.length }})
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ResponseHelper from "../utils/ResponseHelper";
import Archives from "./MyFolders/Archives.vue";
export default {
  name: "MyArchives",
  components: {
    Archives,
  },
  data() {
    return {
      folders: [],
      toast: new ResponseHelper(),
      loading: false,
      timerId: null,
      timer: 0,
      displayLimit: this.setDefaultListShow(),
    };
  },
  created() {
    this.myArchives();
  },
  updated() {
    this.folders = this.archives_folders;
  },
  watch: {
    archives_folders: {
      handler(newFolder) {
        this.folders = newFolder;
      },
    },
  },
  computed: {
    ...mapGetters("folders", ["archives_folders"]),

    displayedFolders() {
      return this.folders.slice(0, this.displayLimit);
    },
    remainingFolders() {
      return this.folders.slice(this.displayLimit);
    },
  },
  methods: {
    ...mapActions("folders", ["my_archives"]),

    showMore() {
      if (this.$route.name !== "Dashboard") {
        this.displayLimit += 8;
      } else {
        this.$router.push({ name: "MyFolders" });
      }
    },
    setDefaultListShow() {
      if (this.$route.name !== "Dashboard") {
        return 16;
      } else {
        return 8;
      }
    },
    async myArchives() {
      try {
        const response = await this.my_archives();
        this.folders = response.data;
      } catch (e) {
        this.toast.sendError(e);
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timerId);
  },
};
</script>

<style scoped>
.show-more-btn {
  border: 1px solid #ddd !important;
  background-color: #fff !important;
}
.show-more-btn:hover {
  border: 1px solid blue !important;
  background-color: #fff !important;
  font-weight: 400;
}
</style>

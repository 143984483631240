<template>
  <div>
    <v-dialog max-width="400" v-model="dialog">
      <v-card>
        <v-card-title class="text-h6 lighten-2">
          Delete folder
          <v-spacer></v-spacer>
          <v-icon @click="closeDialog" size="18" class="white--black">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-6 text-left"
          >Deleting this folder named
          <b>{{ folder_item.name }}</b>
          will delete all the recordings to recycle bin.</v-card-text
        >
        <v-card-actions class="pb-10 pt-5">
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDialog"
            class="grey--text capitalize px-10"
            rounded
            text
          >
            Close
          </v-btn>
          <v-btn
            @click="handleDelete(folder_item)"
            class="capitalize px-10"
            color="error"
            rounded
            :loading="loading"
          >
            Delete folder
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "DeleteFolderDialog",
  props: {
    type: String,
    default: null,
    folder: Object,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      folder_item: {},
    };
  },
  methods: {
    ...mapActions("folders", ["delete_folder"]),
    async handleDelete(folder) {
      this.loading = true;
      try {
        const response = await this.delete_folder(folder);
        this.$emit("load-folders", folder.parent_id);
        this.loading = false;
        this.closeDialog();
        this.$parent.toast.sendSuccess(response);
      } catch (e) {
        this.loading = false;
        this.$parent.toast.sendError(e);
      }
    },
    openDialog(folder) {
      this.folder_item = Object.assign({}, folder);
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <!-- Folder Container -->
    <v-row class="transparent mb-4">
      <div v-if="foldersDetails && foldersDetails.length === 0" class="pl-3">
        No folder found!
      </div>
      <v-col
        v-for="folder in foldersDetails"
        :key="folder.id"
        cols="4"
        lg="3"
        md="4"
        sm="6"
        class="mt-2 text--black"
      >
        <v-card
          class="mx-auto rounded-xl k-folder-box"
          :elevation="0"
          @click.native="openFolder(folder)"
        >
          <v-row>
            <v-col cols="1">
              <v-icon color="primary--text" class="black--text lighten-3">
                mdi-folder-outline
              </v-icon>
            </v-col>
            <v-col cols="9" class="text-sm-left">
              <v-card-text
                class="text-13 capitalize black--text k-folder-content"
              >
                <div v-if="folder.is_renaming_folder">
                  <input
                    v-model="folder.renaming_folder_name"
                    placeholder="Enter a folder name"
                    class="renaming-field"
                    :id="`renaming_${folder.id}`"
                    @click.stop
                    @blur="handleFinalFolderRename(folder, 'blur')"
                    @keydown.enter="handleFinalFolderRename(folder, 'enter')"
                  />
                </div>
                <div
                  v-if="!folder.is_renaming_folder"
                  @click.stop="handleRename(folder)"
                  class="folder-text-name"
                  :title="folder.name"
                >
                  {{ folder.name }}
                </div>
                <small
                  >{{ folder.recordings_count }} recording{{
                    folder.recordings_count == 1 ? "" : "s"
                  }}
                </small>
              </v-card-text>
            </v-col>
            <v-col cols="1" class="k-folder-menu">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="primary--text"
                    class="black--text lighten-3 k-folder-menu-icon"
                  >
                    mdi-dots-horizontal
                  </v-icon>
                </template>
                <v-list class="k-folder-dropdown">
                  <div
                    @click="handleRename(folder)"
                    class="k-folder-dropdown-item"
                  >
                    Rename
                  </div>
                  <div
                    @click="openMoveDialog(folder)"
                    class="k-folder-dropdown-item"
                  >
                    Move
                  </div>
                  <div
                    @click="handleDuplicate(folder)"
                    class="k-folder-dropdown-item"
                  >
                    Duplicate
                  </div>
                  <div
                    @click="openArchiveDialog(folder)"
                    class="k-folder-dropdown-item"
                  >
                    Archive
                  </div>
                  <div
                    @click="openDeleteDialog(folder)"
                    class="k-folder-dropdown-item"
                  >
                    Delete
                  </div>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- Delete folder -->
      <delete-folder-dialog
        ref="deleteFolderDialog"
        @load-folders="myFolders"
      ></delete-folder-dialog>
      <!-- Edit Folder -->
      <move-folder-dialog
        ref="moveFolderDialog"
        @load-folders="myFolders"
      ></move-folder-dialog>
      <!-- Archive Folder -->
      <archive-folder-dialog
        ref="archiveFolderDialog"
        @load-folders="myFolders"
      ></archive-folder-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import ResponseHelper from "../../utils/ResponseHelper";
import DeleteFolderDialog from "../Folders/DeleteFolderDialog.vue";
import MoveFolderDialog from "../Folders/MoveFolderDialog.vue";
import ArchiveFolderDialog from "../Folders/ArchiveFolderDialog.vue";
export default {
  name: "Folders",
  props: {
    foldersDetails: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    DeleteFolderDialog,
    MoveFolderDialog,
    ArchiveFolderDialog,
  },
  data() {
    return {
      toast: new ResponseHelper(),
      tab: null,
      is_renaming_folder: false,
      loading: false,
      timerId: null,
      timer: 0,
      rippleOptions: {
        class: "no-ripple",
      },
    };
  },
  created() {},
  watch: {},
  computed: {},
  methods: {
    ...mapMutations("folders", ["SHOW_DETAILS"]),
    ...mapActions("folders", [
      "my_folders",
      "rename_folder",
      "duplicate_folder",
    ]),

    async myFolders(Id) {
      this.$emit("load-folders", Id);
    },
    handleRename(folder) {
      this.$set(folder, "is_renaming_folder", true);
      this.$set(folder, "renaming_folder_name", folder.name);
      this.$nextTick(() => {
        const renameField = document.getElementById(`renaming_${folder.id}`);
        renameField.focus();
        renameField.select();
      });
      // focus text
    },
    async handleFinalFolderRename(folder, action = "enter") {
      const old_name = folder.name;
      this.$set(folder, "is_renaming_folder", false);
      this.$set(folder, "renaming_folder_name", folder.renaming_folder_name);
      this.loading = true;
      const new_name = folder.renaming_folder_name;
      const __id = folder.id;

      if (old_name != new_name) {
        clearTimeout(this.timerId);

        this.timerId = setTimeout(async () => {
          if (action === "enter" || action == "blur") {
            const form = { name: new_name, id: __id };
            try {
              const response = await this.rename_folder(form);
              this.$emit("load-folders", folder.parent_id);
              this.loading = false;
              this.toast.sendSuccess(response);
            } catch (e) {
              this.loading = false;
              this.toast.sendError(e);
            }
          }
        }, 200);
      }
      this.loading = false;
    },
    openMoveDialog(folder) {
      this.$refs.moveFolderDialog.openDialog(folder);
    },
    async handleDuplicate(folder) {
      this.loading = true;
      try {
        const response = await this.duplicate_folder(folder);
        this.$emit("load-folders", folder.parent_id);
        this.loading = false;
        this.toast.sendSuccess(response);
      } catch (e) {
        this.loading = false;
        this.toast.sendError(e);
      }
    },
    openArchiveDialog(folder) {
      this.$refs.archiveFolderDialog.openDialog(folder);
    },
    openDeleteDialog(folder) {
      this.$refs.deleteFolderDialog.openDialog(folder);
    },
    openFolder(folder) {
      this.$router.push({ name: "FoldersView", params: { id: folder.id } });
    },
  },
  beforeDestroy() {
    clearTimeout(this.timerId);
  },
};
</script>

<style scoped>
.k-folder-box {
  border: 2px solid #ddd;
  padding: 15px 12px;
  max-height: 90px;
  border-radius: 15px !important;
}
.k-folder-box:hover {
  cursor: pointer;
  border: 2px solid blue;
}
.k-folder-content {
  height: 40px;
  padding: 0px !important;
  padding-left: 6px !important;
  margin: 0px !important;
  font-weight: 900 !important;
}
.k-folder-content > small {
  font-weight: 200 !important;
}
.folder-text-name {
  color: #000 !important;
  font-weight: 600 !important;
  padding: 0px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* Folder dropdown styles */
.k-folder-menu {
  padding: 0px;
  padding-top: 10px;
}
.k-folder-menu-icon {
  border-radius: 50%;
  padding: 2px;
}
.k-folder-menu-icon:hover {
  background-color: #ddd;
  color: #000 !important;
}
.k-folder-dropdown {
  width: 150px !important;
  text-align: left !important;
  padding: 10px 10px;
}
.k-folder-dropdown-item {
  padding: 0px 13px;
  margin-bottom: 2px;
  font-size: 13px;
  font-weight: 600;
}
.k-folder-dropdown > .k-folder-dropdown-item {
  cursor: pointer;
  border-radius: 50px;
  height: 35px !important;
  line-height: 32px;
  border: 1px solid #fff !important;
}
.k-folder-dropdown > .k-folder-dropdown-item:hover {
  background-color: #dbdbdb;
  border: 1px solid #ddd !important;
}
/* ./ Dropdown menu */
.renaming-field {
  max-height: 21px !important;
  max-width: 100%;
}
.renaming-field:focus {
  outline: none;
}
.renaming-field::placeholder {
  font-size: 12px;
  font-weight: 400;
}
/* .highlighted {
  background-color: #f5f5f5;
} */
</style>
